import React, {Component, Fragment, Suspense, useEffect} from "react";
import ParserOptions from "./ParserOptions";
import {convertToJson, getTraversalObj, j2xParser, validate} from 'fast-xml-parser'
import Modes from "./ResultModes"
import ToolBarDemo from "./ToolBar";
import axios from 'axios';
import {baseUrl} from './url'
import Encoder from "./ext/app-encoder";
import Formatter from "./ext/AppFormatter";
import {EditorConfig} from "./Themes";
import {NotificationComp} from "./NotificationComp";
import moment from 'moment';

import InputModes from "./InputModes";


const NavBarTailwind = React.lazy(() => import("./NavBarTailwind"))
const SearchPopUp = React.lazy(() => import("./SearchPopUp"))
const EditNamePopup = React.lazy(() => import("./EditNamePopup"))
const ContactUs = React.lazy(() => import("./ContactUs"))
const ConfigPopUp = React.lazy(() => import("./ConfigPopUp"))
const AboutModal = React.lazy(() => import("./AboutModal"))
const ModalDemo = React.lazy(() => import("./ModalDemo"))

const SplitterDemo = React.lazy(() => import('./SplitterDemo'))




export const SpinnerComponent = (props) => {
    useEffect(() => {

        return () => {
            // Anything in here is fired on component unmount.
            if(props.onRemove){
                props.onRemove()
            }

        }
    }, [])


    return (

        <div className={`flex justify-center items-center dark:bg-DarkBG ${props.extraClasses ? props.extraClasses : ''}`} style={props.extraStyles ? props.extraStyles : {}}>
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500">

            </div>
        </div>
    )
}

export class CodeBeautifier extends Component {

    constructor(props) {
        super(props);

        this.state = {
            inputMode:props.inputMode,
            navBarLoaded:false,
            showRightbar:true,
            isDragging:false,
            saveName:'Untitled1',
            lastSaved:'',
            content:props.content,
            editorRef: React.createRef(),
            resultEditRef: React.createRef(),
            splitter: React.createRef(),
            resultWrapper: React.createRef(),
            popUpRef: React.createRef(),
            aboutPopUp: React.createRef(),
            configPopUp: React.createRef(),
            editorMenuBar: React.createRef(),
            navBar: React.createRef(),
            toolBar: React.createRef(),
            searchPopup: React.createRef(),
            editNamePopup: React.createRef(),
            contactUs: React.createRef(),
        };

    }




    componentDidMount = () => {
        localStorage && localStorage.removeItem("uuid")

        if(this.state.navBar  && this.state.navBar.current)
        this.state.navBar.current.configUpdateCall();

        let saved = localStorage.getItem("lastSaved")
        if(saved){
            this.setState({lastSaved:saved})
        }

        const queryParams = new URLSearchParams(this.props.location.search);
        const name = queryParams.get('name'); 
        if(name){
            this.setState({saveName:name})
        }

    }
    processInputData = () => {
        if (this.props && this.props.location && this.props.location.search) {
            let urlSearchParams = new URLSearchParams(this.props.location.search);
            let identifier = `?id=${urlSearchParams.get("id")}&t=${this.props.inputMode}`;
            if (urlSearchParams && urlSearchParams.get("id")) {
                this.getData(identifier);
            }
        }
    }
    getData = (identifier) => {
        // ${baseUrl}
        axios.get(`${baseUrl}/api/xml-editor/${identifier}`)
            .then(res => {
                console.log("res",res)
                let objectData = res.data.data[0];
                let content = objectData.content;
                let resultType = objectData.result_type;
                let inputType = objectData.content_type;
                let name = objectData.name;
                if(name){
                    this.setState({saveName:name})
                }
                content = Encoder.decodeXml(content);
                // console.log("decoded content --- ",content);
                if(this.state.inputMode === InputModes.XML.id){
                    content = Formatter.xmlBeautify(content);
                }else{
                    content = Formatter.jsonBeautify(content);
                }
                localStorage && localStorage.setItem("uuid", objectData.key)
                this.state.splitter.current.onInputRequestChange(content);
                this.setState({content:content},()=>{
                    this.renderDataWithType(resultType);
                });
            }).catch((error) => {
            console.log(error);
            this.state.addToast([{text: 'Unable to find the document.', options: {appearance: 'error'}}]);
        })
    }

    toggleEditor = () => {
        let inputMode = this.state.inputMode;
        let refObject = {};
        if(inputMode === InputModes.XML.id){
            refObject = InputModes.JSON;
        }else if(inputMode === InputModes.JSON.id){
            refObject = InputModes.XML;
        }
        window.location.replace(refObject.url);
    }
    renderDataWithType  = (resultMode) => {
        console.log(resultMode);
        if (Modes.XML.id === resultMode) {
            this.xmlProcess();
        } else if (Modes.JSON.id === resultMode) {
            this.jsonProcess();
        } else if (Modes.TREE.id === resultMode) {
            this.treeProcess(this.state.changeToolBarMode,resultMode);
        } else {
            this.getResultEditor().setValue(this.getInputEditor().getValue());
        }
    }
    renderData = () => {
        let resultMode = this.getResultMode();
        this.renderDataWithType(resultMode);
    };
    downloadData = () => {
        let xmlData = this.getInputEditor().getValue();
        let blob = new Blob([xmlData], { type: 'text/plain' }); 
        const timestamp = moment().format('MM-DD-YYYY_HH-mm-ss');
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob); 
        link.download = `xmleditoronline_${timestamp}.txt`; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
    };
    validateContent = () => {
        let xmlData = this.getInputEditor().getValue();
        this.validateData(xmlData,true)
    };
    uploadData = (data) => {
        if(data){
            this.setState({...this.state,content:data},()=>{
                this.state.splitter.current.onInputRequestChange(data);
            });
        }
    };

    getInputEditor = () => {
        return this.state.splitter && this.state.splitter.current && this.state.splitter.current.getInputEditor();
    }


    getResultEditor = () => {
        return this.state.splitter.current.getResultEditor();
    }

    getResultSession = () => {
        return this.state.splitter.current.getResultSession();
    }

    getInputSession = () => {
        return this.state.splitter.current.getInputSession();
    }

    getResultBlock = () => {
        return this.state.splitter.current.getResultBlock();
    }

    getResultMode = () => {
        return this.state.splitter.current.getResultMode();
    }

    copyData = () => {
        let inputEditor = this.getInputEditor();
        let sel = inputEditor.selection.toJSON();
        inputEditor.selectAll();
        inputEditor.focus();
        document.execCommand('copy');
        inputEditor.selection.fromJSON(sel);

    }

    copyDataSelected = () => {
        let inputEditor = this.getInputEditor();
        inputEditor.focus();
        document.execCommand('copy');


    }

    cutDataSelected = () => {
        let inputEditor = this.getInputEditor();
        inputEditor.focus();
        document.execCommand('cut');

    }

    handleNavigation = (payload) => {
        const {navigate} = this.props;
        navigate(payload);
      };

    pasteDataSelected = () => {
        let inputEditor = this.getInputEditor();
        inputEditor.focus();
        // let inputSession = this.getInputSession();
        debugger;
        // inputEditor.execCommand('paste',inputEditor.getCopyText());
    }
    clearData = () => {
        this.state.splitter.current.onInputRequestChange('');
        this.state.splitter.current.onInputResultChange('');
    }
    cutData = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(this.getInputEditor().getValue())
          } else {
            const input = document.createElement('textarea')
            input.value = this.getInputEditor().getValue()
            document.body.appendChild(input)
            input.select()
            document.execCommand('copy')
            document.body.removeChild(input)
          }
        this.state.splitter.current.onInputRequestChange('');
        this.state.splitter.current.onInputResultChange('');
    }
    clearData = () => {
        this.state.splitter.current.onInputRequestChange('');
        this.state.splitter.current.onInputResultChange('');
    }

    undo = () => {



        this.getInputEditor().undo();
    }


    redo = () => {
        this.getInputEditor().redo();
    }


    findCommand = () => {
        this.getInputEditor().execCommand('find');
    }
    findReplaceCommand = () => {
        this.getInputEditor().execCommand('replace');
    }

    toggleWrap = () => {
        if(this.state.splitter && this.state.splitter.current && this.state.splitter.current.toggleWordWrap){
            this.state.splitter.current.toggleWordWrap();
        }
    }

    toggleLineNumbers = () => {
        if(this.state.splitter && this.state.splitter.current && this.state.splitter.current.toggleLineNumbers){
            this.state.splitter.current.toggleLineNumbers();
        }
    }


    setEditorTheme = () => {
        let initValue = '';
        if(this.state.splitter && this.state.splitter.current && this.state.splitter.current.state && this.state.splitter.current.state.theme){
            initValue = this.state.splitter.current.state.theme;
        }
        let themeStorage = localStorage && localStorage.getItem("theme") === 'dark';
        let themeConfig = EditorConfig.themes.value;
        let filterThemes = themeConfig.filter(f => f.isDark === themeStorage);
        let changeEditorTheme = this.state.splitter.current.changeEditorTheme;
        let configPopUp = this.getConfigPopUp();
        if(configPopUp){
            configPopUp.openConfig(filterThemes,EditorConfig.themes.desc,this.state.splitter,initValue,changeEditorTheme);
        }
    }

    changeKeyboardHandler = () => {
        let initValue = '';
        if(this.state.splitter && this.state.splitter.current && this.state.splitter.current.state && this.state.splitter.current.state.keyboardHandler){
            initValue = this.state.splitter.current.state.keyboardHandler;
        }
        let changeKeyboardHandler = this.state.splitter.current.changeKeyboardHandler;
        let configPopUp = this.getConfigPopUp();
        if(configPopUp){
            configPopUp.openConfig(EditorConfig.keyBinding.value,EditorConfig.keyBinding.desc,this.state.splitter,initValue,changeKeyboardHandler);
        }
    }

    setEditorFont = () => {
        let initValue = '';
        if(this.state.splitter && this.state.splitter.current && this.state.splitter.current.state && this.state.splitter.current.state.fontSize){
            initValue = this.state.splitter.current.state.fontSize;
        }
        let changeEditorSize = this.state.splitter.current.changeEditorSize;
        let configPopUp = this.getConfigPopUp();
        if(configPopUp){
            configPopUp.openConfig(EditorConfig.font.value,EditorConfig.font.desc,this.state.splitter,initValue,changeEditorSize);
        }
    }

    toggleLeftPane = () => {

        if(this.state.splitter && this.state.splitter.current && this.state.splitter.current.toggleLeftPane){
            this.state.splitter.current.toggleLeftPane();
        }
    }

    toggleRightPane = () => {

        if(this.state.splitter && this.state.splitter.current && this.state.splitter.current.toggleRightPane){
            this.state.splitter.current.toggleRightPane();
        }
    }

    toggleDarkMode = () => {

        let checkbox = document.getElementById("toggle");
        let html = document.querySelector("html");
        let theme = !checkbox.checked;

        theme
            ? html.classList.add("dark")
            : html.classList.remove("dark")

        localStorage && localStorage.setItem("theme",theme ? "dark" : "light");
        if(this.updateTheme){
            this.updateTheme();
        }
    }
    changeSplitterOrientation = () => {
        console.log("state==>",this.state)
        if(this.state.splitter && this.state.splitter.current && this.state.splitter.current.changeSplitterOrientation){
            this.state.splitter.current.changeSplitterOrientation();
        }
    }

    updateTheme = (force = false) => {

        if(this.state.splitter && this.state.splitter.current && this.state.splitter.current.updateTheme){
            this.state.splitter.current.updateTheme(force);
        }
    }

    getConfigPopUp = () => {
        return this.state.configPopUp && this.state.configPopUp.current;
    }

    processNavBar = (callBack) => {
        if(callBack){
            callBack();
        }
        let inputEditor = this.getInputEditor();
        inputEditor.focus();
    }
    minifiedInputEditor = () => {
        let inputEditor = this.getInputEditor();
        let xmlData = inputEditor.getValue();
        let annotations= this.getInputSession().getAnnotations()
        let inputMode = this.state.inputMode;
        if(inputMode === InputModes.XML.id){
            let validation = this.validateData(xmlData);
            if (validation.status === true) {
                let minifiedXml = Formatter.xmlMinified(xmlData);
                this.setState({...this.state,content:minifiedXml},()=>{
                    this.state.splitter.current.onInputRequestChange(minifiedXml);
                });
            }
        }else{
            if(annotations && annotations.length === 0) {
                let jsonMinified = Formatter.jsonMinified(xmlData);
                this.setState({...this.state, content: jsonMinified}, () => {
                    this.state.splitter.current.onInputRequestChange(jsonMinified);
                });
            }
        }
        if(annotations && annotations.length > 0){
            this.convertAnnotationsToToast(annotations)
        }

    }
    expandInputEditor = () => {
        let xmlData = this.getInputEditor().getValue();
        let annotations= this.getInputSession().getAnnotations()
        let inputMode = this.state.inputMode;
        if(inputMode === InputModes.XML.id){
            let validation = this.validateData(xmlData);
            if (validation.status === true) {
                let beautifyInput = Formatter.xmlBeautify(xmlData);
                this.setState({...this.state,content:beautifyInput},()=>{
                    this.state.splitter.current.onInputRequestChange(beautifyInput);
                });

            }
        }else{
            if(annotations && annotations.length === 0){
                let beautifyInput = Formatter.jsonBeautify(xmlData);
                this.setState({...this.state,content:beautifyInput},()=>{
                    this.state.splitter.current.onInputRequestChange(beautifyInput);
                });
            }
        }
        if(annotations && annotations.length > 0){
            this.convertAnnotationsToToast(annotations)
        }
    }
    minifiedResults = () => {
        this.getResultBlock().minifiedData();
    }
    expandResults = () => {
        this.getResultBlock().expandedData();
    }
    xmlProcess = () => {
        let value = this.getInputEditor().getValue();

        let parsedXml; 
        if(value){
          parsedXml = this.parseToXml(value,this.getInputSession().getAnnotations());

        }

        this.setState({...this.state,content:value},()=>{
            // console.log("after state update",this.state.content)
            this.state.splitter.current.onInputRequestChange(this.state.content);
            if(parsedXml)
            this.getResultBlock().processResults(Modes.XML, parsedXml);
        });



    };
    jsonProcess = (callback,mode) => {
        let value = this.getInputEditor().getValue();
        let message;
        if(value){
            message = this.parseToJson(value,this.getInputSession().getAnnotations());  
          }
      
        this.setState({content:value},()=>{
            this.state.splitter.current.onInputRequestChange(value);
            if(message)
            this.getResultBlock().processResults(Modes.JSON, message);
        });
    };

    validateData = (value,isValidate=false) => {
        let status = { status: false };
        try {
            let results = validate(value.trim());
            if (results.err) {
                status = { status: false, ...results };

                let annotations = this.getInputSession().getAnnotations();
                if(annotations && annotations.length){
                    this.convertAnnotationsToToast(annotations)
                }else{
                    this.state.addToast([{text:results.err.msg,options: { appearance: 'error' }}]);
                }
                
            } else {
                if(isValidate){
                    this.state.addToast([{text:'Validated successfully',options: { appearance: 'success' }}]);
                }else{
                    status = { status: results };
                }
            }

        } catch (e) {
            console.log(e);
            this.state.addToast([{text:'Something Went Wrong',options: { appearance: 'error' }}]);
            status = { status: false, ...e }
        }
        return status;
    }

    convertAnnotationsToToast = (annotations) => {
        let mapper =  annotations.map(a => {
            return {text:a.text,
                options: { appearance: a.type }
            }
        })
        this.state.addToast(mapper);
    }
    parseToXml = (value,annotations) => {



        let inputMode = this.state.inputMode;

        if(inputMode === InputModes.XML.id){
            // console.log('validation value ---',value)
            let validation = this.validateData(value);
            if (validation.status === true) { //optional (it'll return an object in case it's not valid)
                // console.log("annotations",annotations)
                if(this.state.removeAllToasts){
                    this.state.removeAllToasts()
                }
                let xmlBeautify = Formatter.xmlBeautify(value);

                return xmlBeautify;
            }else{
                // console.log("validation",validation)
            }
        }else{
        //     do for json
            // console.log("annotations",annotations)
            if(annotations && annotations.length > 0){
                this.convertAnnotationsToToast(annotations)
            }else{
                let jsonContent = JSON.parse(value);
                let mainKeys = Object.keys(jsonContent);
                let root = {};
                if(mainKeys.length === 1){
                    root= jsonContent;
                }else{
                    root.root = jsonContent;
                }
                let parse = new j2xParser().parse(root,{});
                return Formatter.xmlBeautify(parse);
            }

        }


    }
    parseToJson = (value,annotations) => {
        let inputMode = this.state.inputMode;

        if(inputMode === InputModes.XML.id){
            let validation = this.validateData(value);
            if (validation.status === true) {

                // debugger;
                let traversalObj = getTraversalObj(value, ParserOptions);
                let javaScriptVal = convertToJson(traversalObj, ParserOptions);
                let message = Formatter.jsonBeautify(javaScriptVal);

                return message;
            }
        }else{
            if(annotations && annotations.length > 0){
                this.convertAnnotationsToToast(annotations)
            }else {
                let jsonValue = JSON.parse(value);
                let message = Formatter.jsonBeautify(jsonValue);
                return message;
            }
        }


        return "";
    }
    treeProcess = (callback,mode) => {

        let value = this.getInputEditor().getValue();

        let  annotations= this.getInputSession().getAnnotations()
      
        let javaScriptVal = {};
        let inputMode = this.state.inputMode;
        if(inputMode === InputModes.XML.id){
            console.log("inside if")
            let validation = this.validateData(value);
            if (validation.status === true) {

                let traversalObj = getTraversalObj(value, ParserOptions); 
                javaScriptVal = convertToJson(traversalObj, ParserOptions);

            }
        }
        
        else{
            console.log("inside else")
            if(annotations && annotations.length > 0){
                this.convertAnnotationsToToast(annotations)
            }else {
                let parseValue = JSON.parse(value);
                let mainKeys = Object.keys(parseValue);
                if (mainKeys.length > 1) {
                    javaScriptVal.root = parseValue;
                } else {
                    javaScriptVal = parseValue;
                }
            }
        }

        console.log("state update")
        this.setState({content:value},()=>{
            this.state.splitter.current.onInputRequestChange(value);
        });

        console.log("call back")
        if(annotations && annotations.length === 0){
            console.log("Modes",Modes.TREE,javaScriptVal)
            this.getResultBlock().processResults(Modes.TREE, javaScriptVal);
            if(callback){
                callback(mode)
            }

        }

    };

    saveContent = (refresh = false, shareIt = false) => {
        let userKey = window.location.href;
        let xmlData = this.getInputEditor().getValue();
        let uuid = localStorage && localStorage.getItem("uuid");
        let validation = {status: true};
        let inputMode = this.state.inputMode;
        if (inputMode === InputModes.XML.id) {
            validation = this.validateData(xmlData)
        }
        if (validation.status === true) {

            let minifiedContent = "";
            let refObject = {};
            if (inputMode === InputModes.XML.id) {
                minifiedContent = Formatter.xmlMinified(xmlData);
                refObject = InputModes.XML
            } else if (inputMode === InputModes.JSON.id) {
                minifiedContent = Formatter.jsonMinified(xmlData);
                refObject = InputModes.JSON
            }
            let encodedString = Encoder.encodeXml(minifiedContent);
            let formData = new FormData();    //formdata object
            formData.append('xml', encodedString);   //append the values with key, value pair
            formData.append('key', uuid);
            formData.append('type', refObject.id);
            formData.append('name', this.state.saveName);
            formData.append('result', this.getResultMode());
            console.log("formData",formData)
            const config = {
                headers: {'content-type': 'multipart/form-data'}
            }
            // console.log("window.location --- " + window.location)
            if (uuid) {
                // ${baseUrl}
                axios.put(`${baseUrl}/api/xml-editor/${uuid}`, formData, config)
                    .then(res => {

                        if (res.data && res.data.code === '00') {
                            // debugger;
                            localStorage && localStorage.setItem("uuid", res.data.key);
                            if (refresh) {
                                window.location.replace(refObject.url + "?id=" + res.data.key + "&name=" + this.state.saveName);
                            } else {
                                // this.props.history.push(refObject.url + "?id=" + res.data.key + "&name=" + this.state.saveName);
                                let url = refObject.url + "?id=" + res.data.key + "&name=" + this.state.saveName
                                this.handleNavigation(url)
                                this.state.splitter.current.onInputRequestChange(xmlData);

                                if (shareIt) {
                                    this.state.popUpRef.current.openModal(userKey);
                                }
                            }
                            this.setState({lastSaved:moment().format('YYYY-MM-DD HH:mm:ss')})
                            localStorage.setItem('lastSaved', moment().format('YYYY-MM-DD HH:mm:ss'))
                            this.state.addToast([{text: 'Update SuccessFully', options: {appearance: 'success'}}]);
                            //this.state.splitter.current.onInputRequestChange(parsedXml);
                        } else {
                            this.state.addToast([{text: 'Something Went Wrong', options: {appearance: 'error'}}]);
                        }
                    }).catch((error) => {
                    console.log(error);
                    this.state.addToast([{text: 'Something Went Wrong', options: {appearance: 'error'}}]);
                })
            } else {
                // ${baseUrl}
                axios.post(`${baseUrl}/api/xml-editor`, formData, config)

                    .then(res => {
                        // debugger;
                        console.log("response==>",res,this.props,this.props.navigate)
                        if (res.data && res.data.code === '00') {
                            localStorage && localStorage.setItem("uuid", res.data.key);
                            if (refresh) {
                                window.location.replace(refObject.url + "?id=" + res.data.key + "&name=" + this.state.saveName);
                            } else {
                                let url = refObject.url + "?id=" + res.data.key + "&name=" + this.state.saveName
                                // this.props.history.push(refObject.url + "?id=" + res.data.key + "&name=" + this.state.saveName);
                                this.handleNavigation(url)
                                this.state.splitter.current.onInputRequestChange(xmlData);
                                if (shareIt) {
                                    this.state.popUpRef.current.openModal(userKey + "?id=" + res.data.key);
                                }
                            }
                            this.setState({lastSaved:moment().format('YYYY-MM-DD HH:mm:ss')})
                            localStorage.setItem('lastSaved', moment().format('YYYY-MM-DD HH:mm:ss'))
                            this.state.addToast([{text: 'Saved SuccessFully', options: {appearance: 'success'}}]);
                            //
                        } else {
                            this.state.addToast([{text: 'Something Went Wrong', options: {appearance: 'error'}}]);
                        }
                    }).catch((error) => {
                    console.log(error);

                    this.state.addToast([{text: 'Something Went Wrong', options: {appearance: 'error'}}]);
                })
            }
        }

        
        // /rest/api.php?request=call



    }

    shareContent = () => {
        this.saveContent(false, true);
    }

    aboutContent = () => {
        this.state.aboutPopUp.current.openModal();
    }
    handleFull = () => {
        document.documentElement.requestFullscreen();
    }

    getInputEditorOptions = () => {
        let inputEditor = this.getInputEditor();
        return inputEditor ? inputEditor.getOption() : {};
    }

    onToolBarMount = (map) => {
        let obj = {};
        map.forEach(prop => {
           obj[prop.key] = prop.func;
        });

        this.setState(obj)
    }

    onToastMount = (map) => {
        let obj = {};
        map.forEach(prop => {
            obj[prop.key] = prop.func;
        });

        this.setState(obj)
    }

    searchDataPop = () => {
        this.state.searchPopup.current.openSearch(this.searchData);
    }
    editNameDataPop = () => {
        this.state.editNamePopup.current.openSearch();
    }
    handleSaveNamePop = (name) => {
        const regex = /^\S+$/; 
      
        if (!regex.test(name)) {
          this.state.addToast([{ text: 'Name cannot contain spaces or be empty', options: { appearance: 'error' } }]);
          return;
        }
      
        this.setState({ saveName: name });
      };
      
    searchData = (id) => {
        let refObject = {};
        let inputMode = this.state.inputMode;
        if (inputMode === InputModes.XML.id) {
            refObject = InputModes.XML
        } else if (inputMode === InputModes.JSON.id) {
            refObject = InputModes.JSON
        }
        let identifier = `?id=${id}`;
        // this.getData(`${identifier}&t=${this.state.inputMode}`);
        window.location.replace(refObject.url + identifier);
    }

    openNew = () => {

        window.open(window.location.origin);
    }

  

    contactUs= () => {
        this.state.contactUs.current.setState({isOpen:true});
    }
    collapseInput= () => {
        let inputSession = this.getInputSession();
        inputSession.foldAll();
    }
    expandInput= () => {
        let inputSession = this.getInputSession();
        let allFolds = inputSession.getAllFolds();
        inputSession.removeFolds(allFolds)
    }

    functionalProps = {
        renderData:this.renderData,
        downloadData:this.downloadData,
        validateContent:this.validateContent,
        uploadData:this.uploadData,
        xmlProcess:this.xmlProcess,
        jsonProcess:this.jsonProcess,
        treeProcess:this.treeProcess,
        undo:this.undo,
        redo:this.redo,
        minified:this.minifiedInputEditor,
        expand:this.expandInputEditor,
        saveContent:this.saveContent,
        shareContent:this.shareContent,
        minifiedResults:this.minifiedResults,
        expandResults:this.expandResults,
        copyData:this.copyData,
        clearData:this.clearData,
        handleFull:this.handleFull,
        findCommand:this.findCommand,
        findReplaceCommand:this.findReplaceCommand,
        copyDataSelected:this.copyDataSelected,
        cutDataSelected:this.cutDataSelected,
        pasteDataSelected:this.pasteDataSelected,
        toggleLineNumbers:this.toggleLineNumbers,
        toggleWrap:this.toggleWrap,
        setEditorTheme:this.setEditorTheme,
        setEditorFont:this.setEditorFont,
        toggleLeftPane:this.toggleLeftPane,
        toggleRightPane:this.toggleRightPane,
        changeSplitterOrientation:this.changeSplitterOrientation,
        updateTheme:this.updateTheme,
        changeKeyboardHandler:this.changeKeyboardHandler,
        processNavBar:this.processNavBar,
        aboutContent:this.aboutContent,
        toggleDarkMode:this.toggleDarkMode,
        searchData:this.searchDataPop,
        editNameData:this.editNameDataPop,
        handleSaveName:this.handleSaveNamePop,
        openNew:this.openNew,
        cutData:this.cutData,
        clearData:this.clearData,
        expandInput:this.expandInput,
        collapseInput:this.collapseInput,
        contactUs:this.contactUs,
        toggleEditor:this.toggleEditor,
        getInputEditorOptions:this.getInputEditorOptions
    }

    setNavLoad = () => {
        this.setState({navBarLoaded:true})
    }
    onDragEnter = (event) => {
        event.preventDefault(); 
        this.setState({ isDragging: true }); // Start blur effect
      };
    
      onDragLeave = (event) => {
        event.preventDefault(); 

        this.setState({ isDragging: false }); // Remove blur effect
      };
      handleDragOver = (event)=>{
        event.preventDefault();
        this.setState({ isDragging: true }); 
      }

    handleDrop = (event) => {
        event.preventDefault(); // Prevent page refresh on drop
        this.setState({ isDragging: false }); 
        const file = event.dataTransfer.files[0]; // Get the first dropped file
        
        if (file && file.type === "text/plain") {
          const reader = new FileReader();
          
          // Read the file content
          reader.onload = (e) => {
            this.setState({...this.state,content:e.target.result},()=>{
                this.state.splitter.current.onInputRequestChange(e.target.result);
            });
          
            // this.setState({ fileContent: e.target.result }); // Update state with content
          };
          
          reader.readAsText(file); // Read the file as text
        } else {
            this.state.addToast([{text: 'Only text files are allowed', options: {appearance: 'error'}}]);
        }
       
      };
    render() {

        /**
         * Based on theme, select icon
         */

        let {popUpRef,aboutPopUp,configPopUp,searchPopup,editNamePopup,contactUs,splitter,
            editorRef,resultEditRef,resultWrapper,navBar,editorMenuBar,changeFont,inputMode,content,changeToolBarMode,navBarLoaded} = this.state;
        // console.log(content,inputMode);
        let mainToolBar = document.getElementById("MainToolBar");
        const toolBarHeight = navBarLoaded ?  document.getElementById("MainToolBar") && document.getElementById("MainToolBar").clientHeight ? mainToolBar.clientHeight+"px" : "0px" :"0px"
        return <>
            <Suspense fallback={<SpinnerComponent extraClasses={'h-screen'} onRemove={this.setNavLoad}/>}>
            <div className="flex dark:bg-darkNavbar bg-lightBg pr-3 border-b border-lightNavbarBorder dark:border-darkNavbarBorder -bg-red-400" id={"MainToolBar"}>
                <div className="w-16 px-2 p-0.5 m-auto">
                    <img src="xmlIcon.svg" className="h-11 w-11 inline-block align-middle" />
                </div>
                <div className="w-full -bg-green-400 py-3">
                    <NotificationComp onToastMount={this.onToastMount}/>
                    <div className="w-full relative">
                    <NavBarTailwind {...this.functionalProps}  ref={navBar}
                                    splitter={splitter}
                                    lastSaved={this.state.lastSaved}
                                    inputMode={inputMode}
                                    saveName={this.state.saveName}
                                    editorMenuBar={editorMenuBar}
                                    />

                <div className="absolute left-0 top-5">
                    <ToolBarDemo
                        {...this.functionalProps} splitter={splitter} onToolBarMount={this.onToolBarMount}
                        />
                    </div>
                    </div>

                    {/*<FullScreen handle={this.props.handleFull}>*/}
                         <ModalDemo ref={popUpRef} buttonText="Copy link" buttonExist={true}/>
                    <AboutModal ref={aboutPopUp} buttonText="Share" buttonExist={false}/>
                    <ConfigPopUp ref={configPopUp} />
                    <SearchPopUp ref={searchPopup} />
                    <EditNamePopup ref={editNamePopup} onSave={this.handleSaveNamePop} name={this.state.saveName} />
                    <ContactUs ref={contactUs} />
                   
                </div>
            </div>
            </Suspense>
            {navBarLoaded ? <Suspense fallback={<SpinnerComponent extraStyles={{height:`calc( 100vh - ${toolBarHeight})`}} />}>
            {/* onDragEnter={(e)=>this.onDragEnter(e)} onDragLeave={(e)=>this.onDragLeave(e)} */}
                <div id={"EditorContainer"}  onDrop={this.handleDrop} onDragLeave={(e)=>this.onDragLeave(e)}   onDragOver={(event) => this.handleDragOver(event)} className={`w-full h-full flex relative`}>
                  
                  {this.state.isDragging && <div className="absolute w-full h-full backdrop-blur-sm bg-[#2d2d2d30] z-10" />}
                  <div className={`${this.state.showRightbar ?'w-10/12':'w-full'} h-full`}>
                <SplitterDemo
                    editorRef={editorRef}
                    resultEditRef={resultEditRef}
                    resultWrapper={resultWrapper}
                    navBar={navBar}
                    editorMenuBar={editorMenuBar}
                    changeFont={changeFont}
                    inputMode={inputMode}
                    content={content}
                    changeToolBarMode={changeToolBarMode}
                    processInputData={this.processInputData}
                    ref={splitter}
                />
                    </div>  
                   {this.state.showRightbar && 
                    <div  className="w-2/12 bg-rightSideColor dark:bg-rightSideDarkColor">
                   
                    </div>
                    }
                </div>
            </Suspense> :<Fragment />}
            {/*{navBarLoaded ? <SpinnerComponent extraStyles={{height:`calc( 100vh - 72px)`}} /> : <Fragment/>}*/}


            {/*<Description />*/}

            {/*</FullScreen>*/}
        </>;
    }
}