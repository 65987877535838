import React, {Fragment,useEffect} from "react";
import {Menu, Transition} from '@headlessui/react'
import {ChevronDownIcon} from '@heroicons/react/solid'

import {BiCollapse, BiCut, BiExpand, BiPlayCircle, BiRedo, BiSave, BiShareAlt, BiUndo} from 'react-icons/bi';
import {AiOutlineCopy, AiOutlineMinus, AiOutlinePlus} from 'react-icons/ai';
import {EditorConfig} from "./Themes";
import CONFIG from "./config";
import ResultModes from "./ResultModes";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ToolBarDemo(props) {

    let config = CONFIG.getConfig();
    const [font,setFont] = React.useState(config.fontSize ? config.fontSize :'12px');
    const [mode,setMode] = React.useState(config.resultMode && config.resultMode.desc ? config.resultMode.desc :ResultModes.XML.desc);

    React.useEffect(() => {

        props.onToolBarMount(
            [
                {key:'changeFont',func:setFont},
                {key:'changeToolBarMode',func:setMode}
            ]
        );
    }, []);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown); 

        return () => {
            window.removeEventListener('keydown', handleKeyDown); 
        };
    }, []);

    const handleKeyDown = (event) => {
        if (event.ctrlKey && (event.key === 's' || event.key === 'S')) {
            event.preventDefault(); 
            props.saveContent(false); 
        }
    };

    const updateFont = (e) => {
        let dataset = e.target.dataset;
        setFont(dataset.id);
        props.splitter.current.changeEditorSize(dataset.id);
    }
    return (
        <div className="w-full">
            <div className="flex w-full items-center">
                {/*Clear Button*/}
                <div title="Cut" className="cursor-pointer p-2 rounded-full dark:hover:bg-iconHoverDark hover:bg-gray-100 has-tooltip" onClick={props.cutData}>
                    {/* <BiCut  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Cut"}/> */}
                    <svg className="text-menuIconLight dark:text-menuIconDark" width="15" height="12" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 12.3179L17.0708 13.6981L9.33521 8.4903L6.43173 10.445C6.5762 10.8178 6.65543 11.2232 6.65543 11.647C6.65543 13.4848 5.16556 14.9747 3.32772 14.9747C1.48987 14.9747 0 13.4848 0 11.647C0 9.8092 1.48987 8.3193 3.32772 8.3193C4.13946 8.3193 4.88331 8.6099 5.4609 9.0928L7.84561 7.4874L5.4609 5.8819C4.88331 6.3648 4.13946 6.6554 3.32772 6.6554C1.48987 6.6554 0 5.1656 0 3.32771C0 1.48987 1.48987 0 3.32772 0C5.16556 0 6.65543 1.48987 6.65543 3.32771C6.65543 3.75155 6.5762 4.15688 6.43173 4.52969L9.33521 6.4845L17.0708 1.27657L18 2.65678L10.8249 7.4874L18 12.3179ZM3.32772 4.99157C2.40879 4.99157 1.66386 4.24664 1.66386 3.32771C1.66386 2.40879 2.40879 1.66386 3.32772 1.66386C4.24664 1.66386 4.99157 2.40879 4.99157 3.32771C4.99157 4.24664 4.24664 4.99157 3.32772 4.99157ZM3.32772 13.3109C2.40879 13.3109 1.66386 12.5659 1.66386 11.647C1.66386 10.7281 2.40879 9.9831 3.32772 9.9831C4.24664 9.9831 4.99157 10.7281 4.99157 11.647C4.99157 12.5659 4.24664 13.3109 3.32772 13.3109Z" fill="currentColor"/>
                    </svg>

                </div>
                {/*Copy Button*/}
                <div title="Copy" className="cursor-pointer p-2 rounded-full dark:hover:bg-iconHoverDark hover:bg-gray-100" onClick={props.copyData}>
                    {/* <AiOutlineCopy className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Copy"}/> */}
                    <svg className="text-menuIconLight dark:text-menuIconDark" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.8847 5.53898L16.8467 1.50098H7.96294V4.73123H3.11719V22.4987H16.0389V19.2685H20.8847V5.53898ZM16.8467 2.64323L19.7424 5.53898H16.8467V2.64323ZM3.92494 21.6917V5.53898H11.1939V9.57698H15.2319V21.6917H3.92494ZM12.0009 5.87348L14.8967 8.76923H12.0009V5.87348ZM16.0389 18.4615V8.76998L12.0009 4.73198H8.77069V2.30873H16.0397V6.34673H20.0777V18.4615H16.0389Z" fill="currentColor"/>
                    </svg>

                </div>
                {/*Expand*/}
                <div title="Expand" className="cursor-pointer p-2 rounded-full dark:hover:bg-iconHoverDark hover:bg-gray-100" onClick={props.expand}>
                    {/* <BiExpand  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Formatted"}/> */}
                    <svg className="text-menuIconLight dark:text-menuIconDark" width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.1429 1.25C15.7286 1.25 15.3929 1.58579 15.3929 2C15.3929 2.41421 15.7286 2.75 16.1429 2.75H20.1893L14.4697 8.46967C14.1768 8.76256 14.1768 9.23744 14.4697 9.53033C14.7626 9.82322 15.2374 9.82322 15.5303 9.53033L21.25 3.81066V7.85714C21.25 8.27136 21.5858 8.60714 22 8.60714C22.4142 8.60714 22.75 8.27136 22.75 7.85714V2C22.75 1.58579 22.4142 1.25 22 1.25H16.1429Z" fill="currentColor"/>
                            <path d="M7.85714 22.75C8.27136 22.75 8.60714 22.4142 8.60714 22C8.60714 21.5858 8.27136 21.25 7.85714 21.25H3.81066L9.53033 15.5303C9.82322 15.2374 9.82322 14.7626 9.53033 14.4697C9.23744 14.1768 8.76256 14.1768 8.46967 14.4697L2.75 20.1893V16.1429C2.75 15.7286 2.41421 15.3929 2 15.3929C1.58579 15.3929 1.25 15.7286 1.25 16.1429V22C1.25 22.4142 1.58579 22.75 2 22.75H7.85714Z" fill="currentColor"/>
                            </svg>

                </div>
                {/*Minified*/}
                <div title="Minified" className="cursor-pointer p-2 rounded-full dark:hover:bg-iconHoverDark hover:bg-gray-100" onClick={props.minified}>
                    {/* <BiCollapse  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Minified"}/> */}
                    <svg className="text-menuIconLight dark:text-menuIconDark" width="17" height="17" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.6208 8.5C20.0351 8.5 20.3708 8.16421 20.3708 7.75C20.3708 7.33579 20.0351 7 19.6208 7L15.5744 7L21.294 1.28033C21.5869 0.98744 21.5869 0.51256 21.294 0.21967C21.0011 -0.0732193 20.5263 -0.0732194 20.2334 0.21967L14.5137 5.93934L14.5137 1.89286C14.5137 1.47864 14.1779 1.14286 13.7637 1.14286C13.3495 1.14286 13.0137 1.47864 13.0137 1.89286L13.0137 7.75C13.0137 8.16421 13.3495 8.5 13.7637 8.5L19.6208 8.5Z" fill="currentColor"/>
                    <path d="M1.89286 12.999C1.47864 12.999 1.14286 13.3348 1.14286 13.749C1.14286 14.1632 1.47864 14.499 1.89286 14.499L5.93934 14.499L0.21967 20.2187C-0.0732194 20.5116 -0.0732193 20.9864 0.21967 21.2793C0.51256 21.5722 0.98744 21.5722 1.28033 21.2793L7 15.5597L7 19.6061C7 20.0204 7.33579 20.3561 7.75 20.3561C8.16421 20.3561 8.5 20.0204 8.5 19.6061L8.5 13.749C8.5 13.3348 8.16421 12.999 7.75 12.999L1.89286 12.999Z" fill="currentColor"/>
                    </svg>

                </div>
                <div title="Expand" className="cursor-pointer p-2 rounded-full dark:hover:bg-iconHoverDark hover:bg-gray-100" onClick={props.expandInput}>
                    {/* <AiOutlinePlus  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Expand"}/> */}
                    <svg className="text-menuIconLight dark:text-menuIconDark" width="18" height="18" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_7769_2119)">
                    <path d="M11.8131 7.7C11.9021 7.79699 12.0103 7.87443 12.1308 7.92741C12.2513 7.98038 12.3815 8.00774 12.5131 8.00774C12.6448 8.00774 12.775 7.98038 12.8955 7.92741C13.016 7.87443 13.1241 7.79699 13.2131 7.7L16.2131 4.75C16.3864 4.57293 16.4914 4.34031 16.5097 4.09327C16.528 3.84622 16.4584 3.60066 16.3131 3.4C16.2322 3.2795 16.1247 3.17914 15.999 3.10664C15.8732 3.03414 15.7325 2.99142 15.5877 2.98176C15.4428 2.97211 15.2977 2.99577 15.1635 3.05094C15.0292 3.1061 14.9093 3.19131 14.8131 3.3L13.5131 4.6V2C13.5131 1.73478 13.4078 1.48043 13.2202 1.29289C13.0327 1.10536 12.7784 1 12.5131 1C12.2479 1 11.9936 1.10536 11.806 1.29289C11.6185 1.48043 11.5131 1.73478 11.5131 2V4.6L10.2131 3.3C10.1169 3.19131 9.99708 3.1061 9.86282 3.05094C9.72855 2.99577 9.58343 2.97211 9.43859 2.98176C9.29375 2.99142 9.15305 3.03414 9.02729 3.10664C8.90154 3.17914 8.79407 3.2795 8.71314 3.4C8.56788 3.60066 8.49824 3.84622 8.51654 4.09327C8.53484 4.34031 8.6399 4.57293 8.81314 4.75L11.8131 7.7Z" fill="currentColor"/>
                    <path d="M13.2131 16.2999C13.1241 16.2029 13.016 16.1255 12.8955 16.0725C12.775 16.0195 12.6448 15.9922 12.5131 15.9922C12.3815 15.9922 12.2513 16.0195 12.1308 16.0725C12.0103 16.1255 11.9021 16.2029 11.8131 16.2999L8.81314 19.2499C8.6399 19.427 8.53484 19.6596 8.51654 19.9067C8.49824 20.1537 8.56788 20.3993 8.71314 20.5999C8.79407 20.7204 8.90154 20.8208 9.02729 20.8933C9.15305 20.9658 9.29375 21.0085 9.43859 21.0182C9.58343 21.0278 9.72855 21.0042 9.86282 20.949C9.99708 20.8938 10.1169 20.8086 10.2131 20.6999L11.5131 19.3999V21.9999C11.5131 22.2651 11.6185 22.5195 11.806 22.707C11.9936 22.8946 12.2479 22.9999 12.5131 22.9999C12.7784 22.9999 13.0327 22.8946 13.2202 22.707C13.4078 22.5195 13.5131 22.2651 13.5131 21.9999V19.3999L14.8131 20.6999C14.9093 20.8086 15.0292 20.8938 15.1635 20.949C15.2977 21.0042 15.4428 21.0278 15.5877 21.0182C15.7325 21.0085 15.8732 20.9658 15.999 20.8933C16.1247 20.8208 16.2322 20.7204 16.3131 20.5999C16.4584 20.3993 16.528 20.1537 16.5097 19.9067C16.4914 19.6596 16.3864 19.427 16.2131 19.2499L13.2131 16.2999Z" fill="currentColor"/>
                    <path d="M3.5127 11H21.5127C21.7779 11 22.0323 10.8946 22.2198 10.7071C22.4073 10.5196 22.5127 10.2652 22.5127 10C22.5127 9.73478 22.4073 9.48043 22.2198 9.29289C22.0323 9.10536 21.7779 9 21.5127 9H3.5127C3.24748 9 2.99312 9.10536 2.80559 9.29289C2.61805 9.48043 2.5127 9.73478 2.5127 10C2.5127 10.2652 2.61805 10.5196 2.80559 10.7071C2.99312 10.8946 3.24748 11 3.5127 11Z" fill="currentColor"/>
                    <path d="M21.5127 13H3.5127C3.24748 13 2.99312 13.1054 2.80559 13.2929C2.61805 13.4804 2.5127 13.7348 2.5127 14C2.5127 14.2652 2.61805 14.5196 2.80559 14.7071C2.99312 14.8946 3.24748 15 3.5127 15H21.5127C21.7779 15 22.0323 14.8946 22.2198 14.7071C22.4073 14.5196 22.5127 14.2652 22.5127 14C22.5127 13.7348 22.4073 13.4804 22.2198 13.2929C22.0323 13.1054 21.7779 13 21.5127 13Z" fill="currentColor"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_7769_2119">
                    <rect width="24" height="24" fill="white" transform="translate(0.512695)"/>
                    </clipPath>
                    </defs>
                    </svg>

                </div>
                <div title="Collapse" className="cursor-pointer p-2 rounded-full dark:hover:bg-iconHoverDark hover:bg-gray-100" onClick={props.collapseInput}>
                    {/* <AiOutlineMinus  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Collapse"}/> */}
                    <svg className="text-menuIconLight dark:text-menuIconDark" width="18" height="18" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_7769_2130)">
                    <path d="M14.8131 8.69993C14.9093 8.80862 15.0292 8.89382 15.1635 8.94899C15.2977 9.00415 15.4428 9.02782 15.5877 9.01816C15.7325 9.0085 15.8732 8.96579 15.999 8.89329C16.1247 8.82079 16.2322 8.72043 16.3131 8.59993C16.4584 8.39926 16.528 8.1537 16.5097 7.90666C16.4914 7.65962 16.3864 7.427 16.2131 7.24993L13.2131 4.29993C13.1241 4.20293 13.016 4.12549 12.8955 4.07252C12.775 4.01954 12.6448 3.99219 12.5131 3.99219C12.3815 3.99219 12.2513 4.01954 12.1308 4.07252C12.0103 4.12549 11.9021 4.20293 11.8131 4.29993L8.81314 7.24993C8.6399 7.427 8.53484 7.65962 8.51654 7.90666C8.49824 8.1537 8.56788 8.39926 8.71314 8.59993C8.79407 8.72043 8.90154 8.82079 9.02729 8.89329C9.15305 8.96579 9.29375 9.0085 9.43859 9.01816C9.58343 9.02782 9.72855 9.00415 9.86282 8.94899C9.99708 8.89382 10.1169 8.80862 10.2131 8.69993L11.5131 7.39993V16.5999L10.2131 15.2999C10.1169 15.1912 9.99708 15.106 9.86282 15.0509C9.72855 14.9957 9.58343 14.972 9.43859 14.9817C9.29375 14.9913 9.15305 15.0341 9.02729 15.1066C8.90154 15.1791 8.79407 15.2794 8.71314 15.3999C8.56788 15.6006 8.49824 15.8461 8.51654 16.0932C8.53484 16.3402 8.6399 16.5729 8.81314 16.7499L11.8131 19.6999C11.9021 19.7969 12.0103 19.8744 12.1308 19.9273C12.2513 19.9803 12.3815 20.0077 12.5131 20.0077C12.6448 20.0077 12.775 19.9803 12.8955 19.9273C13.016 19.8744 13.1241 19.7969 13.2131 19.6999L16.2131 16.7499C16.3864 16.5729 16.4914 16.3402 16.5097 16.0932C16.528 15.8461 16.4584 15.6006 16.3131 15.3999C16.2322 15.2794 16.1247 15.1791 15.999 15.1066C15.8732 15.0341 15.7325 14.9913 15.5877 14.9817C15.4428 14.972 15.2977 14.9957 15.1635 15.0509C15.0292 15.106 14.9093 15.1912 14.8131 15.2999L13.5131 16.5999V7.39993L14.8131 8.69993Z" fill="currentColor"/>
                    <path d="M3.5127 3H21.5127C21.7779 3 22.0323 2.89464 22.2198 2.70711C22.4073 2.51957 22.5127 2.26522 22.5127 2C22.5127 1.73478 22.4073 1.48043 22.2198 1.29289C22.0323 1.10536 21.7779 1 21.5127 1H3.5127C3.24748 1 2.99312 1.10536 2.80559 1.29289C2.61805 1.48043 2.5127 1.73478 2.5127 2C2.5127 2.26522 2.61805 2.51957 2.80559 2.70711C2.99312 2.89464 3.24748 3 3.5127 3Z" fill="currentColor"/>
                    <path d="M21.5127 21H3.5127C3.24748 21 2.99312 21.1054 2.80559 21.2929C2.61805 21.4804 2.5127 21.7348 2.5127 22C2.5127 22.2652 2.61805 22.5196 2.80559 22.7071C2.99312 22.8946 3.24748 23 3.5127 23H21.5127C21.7779 23 22.0323 22.8946 22.2198 22.7071C22.4073 22.5196 22.5127 22.2652 22.5127 22C22.5127 21.7348 22.4073 21.4804 22.2198 21.2929C22.0323 21.1054 21.7779 21 21.5127 21Z" fill="currentColor"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_7769_2130">
                    <rect width="24" height="24" fill="white" transform="translate(0.512695)"/>
                    </clipPath>
                    </defs>
                    </svg>

                </div>
                <div title={"Undo"} className="cursor-pointer p-2 rounded-full dark:hover:bg-iconHoverDark hover:bg-gray-100" onClick={props.undo}>
                    {/* <BiUndo  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Undo"}/> */}
                    <svg className="text-menuIconLight dark:text-menuIconDark" width="18" height="18" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.04302 3.46967C8.33591 3.76256 8.33591 4.23744 8.04302 4.53033L6.32335 6.25H15.5127C18.6883 6.25 21.2627 8.82436 21.2627 12C21.2627 15.1756 18.6883 17.75 15.5127 17.75H8.5127C8.09848 17.75 7.7627 17.4142 7.7627 17C7.7627 16.5858 8.09848 16.25 8.5127 16.25H15.5127C17.8599 16.25 19.7627 14.3472 19.7627 12C19.7627 9.65279 17.8599 7.75 15.5127 7.75H6.32335L8.04302 9.46967C8.33591 9.76256 8.33591 10.2374 8.04302 10.5303C7.75013 10.8232 7.27525 10.8232 6.98236 10.5303L3.98236 7.53033C3.68947 7.23744 3.68947 6.76256 3.98236 6.46967L6.98236 3.46967C7.27525 3.17678 7.75013 3.17678 8.04302 3.46967Z" fill="currentColor"/>
                    </svg>

                </div>
                <div title={"Redo"} className="cursor-pointer p-2 rounded-full dark:hover:bg-iconHoverDark hover:bg-gray-100" onClick={props.redo}>
                    {/* <BiRedo  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Redo"}/> */}
                    <svg className="text-menuIconLight dark:text-menuIconDark" width="15" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9824 0.469668C13.6895 0.762558 13.6895 1.23744 13.9824 1.53033L15.702 3.25H6.5127C3.3371 3.25 0.762697 5.82436 0.762697 9C0.762697 12.1756 3.3371 14.75 6.5127 14.75H13.5127C13.9269 14.75 14.2627 14.4142 14.2627 14C14.2627 13.5858 13.9269 13.25 13.5127 13.25H6.5127C4.1655 13.25 2.2627 11.3472 2.2627 9C2.2627 6.65279 4.1655 4.75 6.5127 4.75H15.702L13.9824 6.46967C13.6895 6.76256 13.6895 7.2374 13.9824 7.5303C14.2753 7.8232 14.7501 7.8232 15.043 7.5303L18.043 4.53033C18.3359 4.23744 18.3359 3.76256 18.043 3.46967L15.043 0.469668C14.7501 0.176777 14.2753 0.176777 13.9824 0.469668Z" fill="currentColor"/>
                    </svg>

                </div>
                <div title="Clear" onClick={props.clearData} className="cursor-pointer p-2 rounded-full dark:hover:bg-iconHoverDark hover:bg-gray-100">
                <svg className="text-menuIconLight dark:text-menuIconDark" width="18" height="18" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5127 9L15.5127 15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15.5127 9L9.5127 15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.5127 21C17.4833 21 21.5127 16.9706 21.5127 12C21.5127 7.02944 17.4833 3 12.5127 3C7.54213 3 3.5127 7.02944 3.5127 12C3.5127 16.9706 7.54213 21 12.5127 21Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <Menu as="div" title="Font size" className="relative cursor-pointer p-2 rounded-full -hover:bg-gray-100">
                    {({ open }) => (
                        <>
                            <div>
                                <Menu.Button className="flex items-center focus:outline-none focus:ring-0 -focus:ring-offset-2 -hover:text-gray-900 -focus:ring-gray-800">
                                    <span className="text-lightIcon dark:text-white -hover:bg-gray-100 text-sm">{font}</span>
                                    <ChevronDownIcon
                                        className={classNames(
                                            open ? 'text-lightIcon' : 'text-lightIcon',
                                            'mt-0.5 h-4 w-4 group-hover:text-gray-500 dark:text-textdarkBlueDark dark:hover:bg-iconHoverDark hover:bg-gray-100 rounded-full'
                                        )}
                                        aria-hidden="true"
                                    />
                                </Menu.Button>
                            </div>
                            <Transition
                                show={open}
                                as={Fragment}
                            >
                                <Menu.Items
                                    static
                                    className="origin-top-right z-50 absolute left-0 mt-2 w-32 shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-DarkBG cursor-pointer"
                                >
                                    
                                       {EditorConfig.font.value.map((item,i)=>(
                                        <Menu.Item onClick={updateFont} data-id={item.name} key={item.id}>
                                            <div
                                                className={classNames(
                                                    'block px-4 py-2 text-sm text-lightIcon text-left dark:hover:bg-iconHoverDark dark:text-white hover:bg-gray-100'
                                                )}
                                            >
                                                {item.desc}</div>
                                            </Menu.Item>
                                       ))

                                       }
                                       
                                        {/* {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-100' : '',
                                                    'block px-4 py-2 text-sm text-lightIcon text-left hover:bg-gray-200 dark:hover:bg-black'
                                                )}
                                                onClick={() => {
                                                    props.xmlProcess(setMode,ResultModes.XML.desc);
                                                    // setMode(ResultModes.XML.desc);
                                                }}
                                            >
                                                {ResultModes.XML.desc}</a>
                                        )} */}
                                    
                                </Menu.Items>
                            </Transition>
                        </>
                    )}
                </Menu>
                {/* <Menu as="div" className="relative">
                    {({ open }) => {
                        let fontMap = EditorConfig.font.value.map(font => {
                            return ( <Menu.Item onClick={updateFont} data-id={font.name} key={font.id}>
                                {({active}) => (
                                    <span
                                        // href="#"
                                        className={classNames(
                                            active ? 'hover:bg-gray-100 dark:hover:bg-black text-gray-600' : '' +
                                            'block px-4 py-2 text-sm text-lightIcon text-left bg-green-400 m-5'

                                        )}
                                    >
                                    {font.desc} hello</span>
                                )
                                }
                            </Menu.Item>);
                        });
                        return (
                            <>
                                <div>
                                    <Menu.Button
                                        className="flex hover:text-gray-900 pt-0.5 px-2.5 focus:outline-none">
                                        <span
                                            className="text-lightIcon dark:text-white -hover:bg-gray-100 text-sm">{font}</span>
                                        <ChevronDownIcon
                                            className={classNames(
                                                open ? 'text-gray-600' : 'text-lightIcon',
                                                'mt-1 h-4 w-4 group-hover:text-gray-500'
                                            )}
                                            aria-hidden="true"
                                        />
                                    </Menu.Button>
                                </div>
                                <Transition
                                    show={open}
                                    as={Fragment}
                                >
                                    <Menu.Items
                                        static
                                        className="origin-top-right z-50 absolute  left-0 mt-2 w-48 shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none
                                        dark:bg-DarkBG cursor-pointer bg-red-400"
                                    >
                                        
                                        {fontMap}
                                    </Menu.Items>
                                </Transition>
                            </>
                        );
                    }}
                </Menu> */}
                <div className="cursor-pointer p-1 px-2 hidden" onClick={() => props.saveContent(false)}>
                    <BiSave  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Save"}/>
                </div>
                <div className="cursor-pointer p-1 px-2 hidden" onClick={props.shareContent}>
                    <BiShareAlt  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Share"}/>
                </div>
                <div id="playButton" className="cursor-pointer p-1 px-2 hidden" onClick={props.renderData}>
                    <BiPlayCircle  className="text-green-700  h-5 w-5" title={"Play"}/>
                </div>

                <Menu as="div" className="relative cursor-pointer p-2 rounded-full -hover:bg-gray-100">
                    {({ open }) => (
                        <>
                            <div>
                                <Menu.Button className="flex items-center focus:outline-none focus:ring-0 -focus:ring-offset-2 -hover:text-gray-900 -focus:ring-gray-800">
                                    <span className="text-lightIcon dark:text-white -hover:bg-gray-100 text-sm">{mode}</span>
                                    <ChevronDownIcon
                                        className={classNames(
                                            open ? 'text-lightIcon' : 'text-lightIcon',
                                            'mt-0.5 h-4 w-4 group-hover:text-gray-500 dark:text-textdarkBlueDark dark:hover:bg-iconHoverDark hover:bg-gray-100 rounded-full'
                                        )}
                                        aria-hidden="true"
                                    />
                                </Menu.Button>
                            </div>
                            <Transition
                                show={open}
                                as={Fragment}
                            >
                                <Menu.Items
                                    static
                                    className="origin-top-right z-50 absolute left-0 mt-2 w-32 shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-DarkBG cursor-pointer"
                                >
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-100' : '',
                                                    'block px-4 py-2 text-sm text-lightIcon text-left dark:hover:bg-iconHoverDark dark:text-white hover:bg-gray-100'
                                                )}
                                                onClick={() => {
                                                    props.xmlProcess(setMode,ResultModes.XML.desc);
                                                    // setMode(ResultModes.XML.desc);
                                                }}
                                            >
                                                {ResultModes.XML.desc}</a>
                                        )}
                                    </Menu.Item>

                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-100' : '',
                                                    'block px-4 py-2 text-sm text-lightIcon text-left dark:hover:bg-iconHoverDark dark:text-white hover:bg-gray-100'
                                                )}
                                                onClick={() => {
                                                    props.jsonProcess(setMode,ResultModes.JSON.desc)
                                                    // setMode(ResultModes.JSON.desc);
                                                }}
                                            >
                                                {ResultModes.JSON.desc}</a>
                                        )}
                                    </Menu.Item>

                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-100' : '',
                                                    'block px-4 py-2 text-sm text-lightIcon text-left dark:hover:bg-iconHoverDark dark:text-white hover:bg-gray-100'
                                                )}
                                                onClick={() => {
                                                    props.treeProcess(setMode,ResultModes.TREE.desc);
                                                    // setMode(ResultModes.TREE.desc);
                                                }}
                                            >
                                                {ResultModes.TREE.desc}</a>
                                        )}
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>
                        </>
                    )}
                </Menu>

            </div>

            {/* <div className="flex w-2/4 justify-end">
                <div className="cursor-pointer p-1 px-2" onClick={props.expandResults}>
                    <BiExpand  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Expand"}/>
                </div>
                <div className="cursor-pointer p-1 px-2" onClick={props.minifiedResults}>
                    <BiCollapse  className="text-lightIcon dark:text-gray-500 h-5 w-5" title={"Collapse"}/>
                </div>
            </div> */}
        </div>
    );
}