import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import VirtualTreeComponent from "./VTreeDemo";
import { CodeBeautifier } from "./CodeBeautifier";
import InputModes from "./InputModes";
import ContactUs from "./ContactUs";
import withNavigate from './withNavigate'; // Import the HOC

const CodeBeautifierWithNavigate = withNavigate(CodeBeautifier); // Wrap the component

function App() {
  
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/tree" element={<VirtualTreeComponent />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route 
            path="/json" 
            element={
              <CodeBeautifierWithNavigate
                inputMode={InputModes.JSON.id}
                content={InputModes.JSON.content}
              />} 
          />
          <Route 
            path="/" 
            element={
              <CodeBeautifierWithNavigate
                inputMode={InputModes.XML.id}
                content={InputModes.XML.content}

              />} 
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
