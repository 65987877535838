import React from 'react';
import {VariableSizeTree as Tree} from 'react-vtree';

// Tree component can work with any possible tree structure because it uses an
// iterator function that the user provides. Structure, approach, and iterator
// function below is just one of many possible variants.

let tree =  {

} ;

function* treeWalker(refresh) {
    const stack = [];

    stack.push({
        nestingLevel: 0,
        node: tree,
    });

    while (stack.length !== 0) {
        const {
            node: {children = [], id, name,value,isAttr,isParent},
            nestingLevel,
        } = stack.pop();

        const isOpened = yield refresh
            ? {
                // The only difference VariableSizeTree `treeWalker` has comparing to
                // the FixedSizeTree is the `defaultHeight` property in the data
                // object.
                defaultHeight: 30,
                id,
                isLeaf: children.length === 0,
                isOpenByDefault: true,
                name,
                nestingLevel,
                value,
                isAttr,
                isParent
            }
            : id;

        if (children.length !== 0 && isOpened) {
            for (let i = children.length - 1; i >= 0; i--) {
                stack.push({
                    nestingLevel: nestingLevel + 1,
                    node: children[i],
                });
            }
        }
    }
}

// Node component receives current node height as a prop
const Node = ({data, height, isOpen, style, toggle,treeData: itemSize}) => {
    let {isLeaf, name,nestingLevel,value,isAttr,isParent} = data;

    const canOpen = height <= itemSize;

    let marginLeft = nestingLevel * 15 + (isLeaf ? 20 : 0);

    let nodeContent = "";
    if(isParent){
        nodeContent = name + " " + value;
    }else if (isAttr){
        nodeContent = name + ":" + value;
    }else{
        nodeContent = value;
    }
    let containerStyle = {
        ...style,
        alignItems: 'center',
        background: canOpen ? undefined : '#ddd',
        display: 'flex',
        marginLeft: marginLeft,
    }
    if(isAttr){
        containerStyle.color = 'darkgrey';
    }

    let buttonStyles = {
        margin:'5px',
        color:'rgb(39 42 47)',
        background:'rgb(169 169 169)',
        border:' 1px solid rgb(189 189 189)',
        padding:'0 5px',
        fontSize:'1.2em',
        lineHeight:'1'
    }
    delete containerStyle.background
    delete containerStyle.width

    return (

        <div style={containerStyle} className={"dark:bg-DarkBG"}>
            {!isLeaf && (
                <button type="button" onClick={toggle} style={buttonStyles}>
                    {isOpen ? '-' : '+'}
                </button>
            )}
            {isParent ? (
                    <span style={{padding: "'0 0 0 12px'", fontWeight: 'bold'}}>
            <span style={{
                margin: '5px',
                background: 'rgb(169 169 169)',
                padding: '1px 5px',
                color: 'rgb(39 42 47)',
                fontWeight: 'lighter',
                fontSize: '1em'
            }}>{name}</span>
                        {value}</span>
                // <div className="parent">{nodeContent}</div>
            ) : isAttr ? (<div><span>{name}:  </span><span>{value}</span></div>) :
                (<div>{nodeContent}</div>)
            }
        </div>
    )
};

let constructTree = function (r,id,d){
    this.name = r;
    this.id = id;
    let keys = [];
    if(typeof d === "object" ){
        keys= Object.keys(d);
    }else{
        this.isString = true;
        this.value = d;
    }

    if(keys.length > 0){
        this.children = [];
    }
    this.isParent = true;
    for (let k of keys) {
        let isAttr = false;
        let isText = false;
        let isArray = false;
        let id1 = id+'~'+k;
        let c = {name:k,id: id1,children:[]};
        // debugger;
        if(typeof d[k] === "object" && k !== "#attr" && (!Array.isArray(d[k]))){
            c.value = '...'

            constructTree.call(c,k,id1,d[k]);
        }else if(Array.isArray(d[k])){
            isArray = true;
            let arrayChild = [];
            for(let i in d[k]){
                let arrayD = {name:k,id: id1,children:[]};
                constructTree.call(arrayD,k,id1+'~'+i,d[k][i]);

                arrayChild.push(arrayD);
                this.children = [...this.children,arrayD];
            }

        }else if(k === "#attr"){
            // debugger;
            let attrKeys = Object.keys(d[k]);
            isAttr = true;
            for(let i in attrKeys){
                this.children = [...this.children,{name:attrKeys[i],id:id1+'~'+i,value:d[k][attrKeys[i]],isAttr:true}];
                // arrayChild.push({name:i,id:k+'~'+i,value:d[k][attrKeys[i]]});
            }
            // c.children = [...c.children,arrayChild];

        }else if(k === "#text"){
            isText = true;
            this.value = d[k];
        }
        else {
            constructTree.call(c, k, id1, d[k]);
        }

        if(!isAttr && !isText && !isArray){
            // debugger;

            this.children = [...this.children,c];
        }

    }
    return this;
}

class VirtualTreeComponent extends React.Component {


    constructor(props, context) {
        super(props, context);

        this.state = {
            treeData:props.data ? props.data :'',
            theme:props.theme ? props.theme :'',
            treeWalker:React.createRef()
        }
    }

    updateTreeNodes = (updatedTree) => {
        this.setState({treeData:updatedTree},() => {
            this.state.treeWalker.current.state.recomputeTree({refreshNodes:true});
        })
    }

    getTree() {
        return this.state.treeWalker.current;
    }
    updateTreeTheme= (theme) => {
        this.setState({...this.state,theme})
    }

    render() {
        let result = {};
        let {treeData,theme} = this.state;
        if (treeData) {


            let rootKey = Object.keys(treeData)[0];
            constructTree.call(result, rootKey, rootKey, treeData);
            tree = result.children[0];
        }
        //Setting Second Container Height
        let height  = 700;
         if(this.props.container && this.props.container.current && this.props.container.current._container && this.props.container.current._container.children){
            let containnerChilds = this.props.container.current._container.children;
            if(containnerChilds.length > 2){
                let secondChild = containnerChilds[2];
                height = secondChild.clientHeight;
            }

            // height = this.props.container._container.clientHeight;
        }
        // if (container && container.children && container.children[2] && container.children[2] && container.children[2].children && container.children[2].children[0] && container.children[2].children[0].style) {
        //     container.children[2].children[0].style.height = `calc( 100vh - ${elementById.clientHeight}px)`;
        // }

        return (
            <Tree treeWalker={treeWalker} height={typeof height === 'string' ? 700 :height} width="100%"  id={"TreeContainer"}  className={"dark:bg-DarkBG"} ref={this.state.treeWalker}>
                {Node}
            </Tree>
        );
    }
}

export default VirtualTreeComponent;
