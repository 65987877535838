import { useNavigate,useLocation  } from 'react-router-dom';

function withNavigate(Component) {
  return function (props) {
    const navigate = useNavigate(); 
    const location = useLocation(); // Get location object
    return <Component {...props} navigate={navigate}  location={location} />;
  };
}

export default withNavigate;