import React from "react";
import ReactDOM from "react-dom/client";
import { ToastProvider } from 'react-toast-notifications';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import App from "./App";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ToastProvider autoDismiss >
      <App />
    </ToastProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.unregister();
reportWebVitals();

