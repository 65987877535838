export default {
    XML : {id:'xml',desc:'XML',url:'/',content:`<?xml version="1.0" encoding="utf-8"?>
<note importance="high" logged="true">
    <title prop="demo">Happy</title>
    <todo>Work</todo>
    <todo>Play</todo>
  </note>`},
    JSON : {id:'json',desc:'JSON',url:'/json',content:`{
 "query": {
  "count": 10,
  "created": "2011-06-21T08:10:46Z",
  "lang": "en-US",
  "results": {
   "photo": [   
    {
     "farm": "4",
     "id": "5856170534",
     "isfamily": "0",
     "isfriend": "0",
     "ispublic": "1",
     "owner": "32021554@N04",
     "secret": "ff1efb2a6f",
     "server": "3217",
     "title": "6975 rusty cat"
    },
    {
     "farm": "6",
     "id": "5856172972",
     "isfamily": "0",
     "isfriend": "0",
     "ispublic": "1",
     "owner": "51249875@N03",
     "secret": "6c6887347c",
     "server": "5192",
     "title": "watermarked-cats"
    },
    {
     "farm": "6",
     "id": "5856168328",
     "isfamily": "0",
     "isfriend": "0",
     "ispublic": "1",
     "owner": "32021554@N04",
     "secret": "0c1cfdf64c",
     "server": "5078",
     "title": "7020 mandy cat"
    }
   ]
  }
 }
}`}
}